import React from 'react';
import logo from './assets/logo/full-white-transparent.png';

const HeaderComingSoon = () => {
  return (
    <header className="absolute top-0 left-0 right-0 z-10 p-4 md:p-6">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white">
            <img src={logo} alt='Billoptim logo' width="291" height="42" className="w-48 md:w-64"/>
        </div>
      </div>
    </header>
  );
};

export default HeaderComingSoon;