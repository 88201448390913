
import React from 'react';
import HeaderComingSoon from './HeaderComingSoon';
// import { toast } from 'sonner';
import loader from '../src/assets/Loaders/triangle1.gif'
const App = () => {
  // const [email, setEmail] = useState('');
  // const [isValidEmail, setIsValidEmail] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   setIsValidEmail(emailRegex.test(email));
  // }, [email]);

  // const handleJoinWaitlist = async () => {
  //   if (!isValidEmail) {
  //     toast.error('Please enter a valid email address');
  //     return;
  //   }

  //   setIsLoading(true);

  //   try {
  //     const response = await fetch('Api.end.point', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ email }),
  //     });

  //     if (response.ok) {
  //       toast.success('Successfully joined the waitlist!');
  //       setEmail('');
  //     } else {
  //       const errorData = await response.json();
  //       toast.error(errorData.message || 'Failed to join the waitlist. Please try again.');
  //     }
  //   } catch (error) {
  //     console.error('Error joining waitlist:', error);
  //     toast.error('An error occurred. Please try again later.');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  return (

    <div className="flex flex-col justify-center items-center min-h-screen bg-black relative p-4 overflow-hidden">
      <HeaderComingSoon />
      <div className="flex flex-col items-center justify-center gap-10 mb-10">
        <img
          src={loader}
          alt="Coming Soon"
          className="max-h-[400px] rounded-3xl"
        />
      </div>
      <div className="relative z-10 text-center px-4 max-w-4xl mx-auto">
        <div className="mb-8">
          <span className="inline-flex items-center bg-blue-600 bg-opacity-50 rounded-full px-4 py-2 sm:px-6 sm:py-3 text-sm sm:text-base md:text-lg font-semibold text-white shadow-lg transition-all duration-300 hover:bg-opacity-70">
            Launching soon
          </span>
        </div>
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl font-bold mb-6 sm:mb-8 leading-tight tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-blue-300">
          We're working hard to bring you BillOptim,<br />the ultimate solution for optimizing your bills.<br />Stay tuned!
        </h1>
        {/* <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`bg-white bg-opacity-20 text-blue-300 placeholder-blue-300 border-2 ${isValidEmail ? 'border-blue-500' : 'border-black-500'
              } rounded-full py-2 px-4 sm:py-3 sm:px-6 w-full sm:w-80 text-base sm:text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300`}
          />
          <button
            onClick={handleJoinWaitlist}
            disabled={!isValidEmail || isLoading}
            className={`w-full sm:w-auto bg-gradient-to-r from-blue-600 to-blue-400 hover:from-blue-700 hover:to-blue-500 text-white font-semibold py-2 px-6 sm:py-3 sm:px-8 rounded-full text-base sm:text-lg transition-all duration-300 transform hover:scale-105 ${!isValidEmail || isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105'
              }`}
          >
            {isLoading ? 'Joining...' : 'Join the waitlist'}
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default App;
